import React, { Component } from "react";
import logo from "../../images/logo/mokai.svg";
import Section from "../global/Section";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import footerStyle from "../style/footer.module.scss";
import { StaticQuery, graphql } from "gatsby";


export default class Footer extends Component {
  state = {
    column: [
      {
        title: "Terms & Policy",
        items: [
          {
            //tos and privacy should put under celer.network
            item: "Terms of Service",
            url: "/tos.pdf",
            target: "_blank",
            rel: "noopener noreferrer",
            
          },
          {
            item: "Privacy Policy",
            url: "/privacy.pdf",
            target: "_blank",
            rel: "noopener noreferrer",
            
          },
        ],
      },
      {
        title: "Contact",
        items: [
          {
            item: "Customer Support: support@chainscale.com",
            url: "mailto:support@chainscale.com",
            target: "_blank",
            rel: "noopener noreferrer",
          },
        ],
      },
    ],
  };
  render() {
    const Community = () => {
      return (
        <StaticQuery
          query={graphql`
            query {
              allCommunityJson {
                edges {
                  node {
                    id
                    url
                    name
                  }
                }
              }
            }
          `}
          render={(data) => {
            return data.allCommunityJson.edges.map((el) => {
              return (
                <div className="col py-3" key={el.node.id}>
                  <a
                    href={el.node.url}
                    className="w"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={["fab", el.node.name]}
                      className="ml-md-auto d-block"
                      size="lg"
                    />
                  </a>
                </div>
              );
            });
          }}
        />
      );
    };

    return (
      <Section bgColor={"black"}>
        <div className="row align-items-center">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="row">
              {this.state.column.map((el, index) => {
                return (
                  <div className="col-md" key={index}>
                    <ul className={footerStyle.footerColumn}>
                      <li className="w font-w-bold py-2 d-block">{el.title}</li>
                      {el.items.map((item, index) => {
                        return (
                          <a
                            key={index}
                            href={item.url}
                            target={item.target}
                            className="l-grey"
                          >
                            <li
                              className="font-w-light"
                              style={{ fontSize: "14px" }}
                            >
                              <p>{item.item}</p>
                            </li>
                          </a>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 my-5">
            <div className="d-flex flex-wrap align-items-center pb-1">
              <a target="_blank" href="/">
                <img src={logo} width="120" alt="logo"/>
              </a>
              <p className="d-grey ml-lg-2 ml-0">
                © Copyright {new Date().getFullYear()} ChainScale. All Rights Reserved
              </p>
            </div>
          </div>
        </div>
        
      </Section>
    );
  }
}
